import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PolicyModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Link href="" color="primary" onClick={handleClickOpen}>
        Solhop Partner Affiliate Terms & Conditions
      </Link>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                Solhop Partner Affiliate Terms & Conditions
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md">
<h2>1. Definitions</h2>
<p>1.1 Capitalised terms shall have the meanings set out in these terms and conditions or in the Partner Affiliate Registration Form or 'PARF':
</p><p>'Partner Affiliate': means the entity named as such in the PARF and does not mean legal partners or affiliates. It is used to describe the relationship by which Solhop and the Partner Affiliate work together; 
</p><p>'Partner Affiliate Website': means the website or websites owned and/or operated by the Partner Affiliate as set out in the PARF; 
</p><p>'Agreement': means the PARF and these terms and conditions (including any schedules); 
</p><p>'Booking Details': means lead Customer's full name, date(s) of Transfer Services to be provided including the number of Customers in addition to the lead Customer (if any), outbound transportation point for collection, airport name, airline, flight number and pick-up time; return transportation collection point, time and destination; 
</p><p>'Commencement Date': means the effective date of the Agreement as stated in the PARF; 
</p><p>'Commission': means Partner Affiliate's remuneration for Consumed Service Bookings; 
</p><p>'Consumed Service Booking': means a Service Booking that has been fulfilled by Transfer Provider; 
</p><p>'Content': means information about Transfer Services and the online booking system; 
</p><p>'Confidential Information': all confidential information and trade secrets (howsoever stored) concerning either party about its businesses including commission, number and value of Service Bookings, all financial, marketing, operational, technical information whether or not such confidential information is marked as confidential or proprietary; 
</p><p>'Customer': means any individual who makes a Service Booking. A lead Customer will be named on the Service Booking. Where more than one person is included in the same Service Booking the term 'Customer' applies to all individuals in the Service Booking; 
</p><p>'Data Protection Legislation': means the Data Protection Act 1998 and all applicable laws and regulations, guidance and codes of practice relating to the processing and use of personal data; 
</p><p>'Intellectual Property Rights': means all patents, rights to inventions, utility models, copyright and related rights, trade marks, service marks, trade, business and domain names, logos, website look and feel, , rights in trade dress or get-up, rights in goodwill or to sue for passing 
off, unfair competition rights, rights in design, rights in computer software, database rights, topography rights, moral rights, brand, rights in confidential information (including know-how and trade secrets) and any other intellectual property rights, in each case, whether registered or unregistered and including all applications for and renewals or extensions of such rights, and all similar or equivalent rights or forms of protection in any part of the world; 
</p><p>'Link': means an icon, object, graphic, or text that may be embedded within a web page or email that consists of a hypertext link to the Website as may be further specified in the PARF; 
</p><p>'Marks': means the trade marks (registered and unregistered) and logos of Partner Affiliate; 
</p><p>'Retail Price': means the price paid by Customer for Transfer Services; 
</p><p>'Partner Affiliate': Partner Affiliate Registration Form; 
</p><p>'Payment Due Date': means, in respect of Commission for any Consumed Service Booking, 15 days after the end of the month in which the Consumed Service Booking took place; 
</p><p>'Private Transfer Services': means a Service Booking for Transfer Services provided exclusively to a Customer; 
</p><p>'Service Booking': means a contract for the provision of Transfer Services between the Transfer Provider and Customer and which has resulted in Consumed Transfer Services on which Partner Affiliate receives Commission; 
</p><p>'Service Booking Request': means a request for a Service Booking made by Consumer to Solhop containing Booking Details; 
</p><p>'Solhop': means Solhop SL, a company registered in Spain with company number B93176154; 
</p><p>'Solhop Marks ': means the trade marks and logos (registered and unregistered) of Solhop reproduced in Website or as part of the Services; 
</p><p>'Term': means the period from the Commencement Date until termination. 
</p><p>'Transfer Provider': means the provider of Transfer Services with whom the Customer has a Service Booking; 
</p><p>'Website': means www.solhop.com, and any other website under the control of Solhop as advised to Partner Affiliate from time to time. 
</p>
<h2>2. Scope and Term of the Agreement</h2>
<p>2.1 In consideration for the Commission, the parties agree that Solhop shall provide Services to Partner Affiliate in the Territory for the Term in accordance with the terms of the Agreement. 
</p><p>2.2 The Agreement replaces any existing agreements or arrangements between Solhop and Partner Affiliate with effect from the Commencement Date (notwithstanding the date of signature of the Agreement) but shall not affect Service Bookings for Transfer Services confirmed prior to the Commencement Date. 
</p><p>2.3 These terms and conditions may be revised by Solhop from time to time. Solhop will notify Partner Affiliate of any such revised terms and conditions and provide a copy in writing. The Agreement will be varied to substitute any such revised terms and conditions to replace these with effect 30 days following the date of such notification. 
</p><h2>3. Licences</h2>
<p>3.1 Solhop grants Partner Affiliate a non-exclusive, non-transferable, royalty-free licence to access and use the Website, the Content and the Solhop Marks to the extent necessary to utilise Services for the Term of the Agreement. 
</p><p>3.2 Partner Affiliate grants Solhop a non-exclusive, non-transferrable, royalty-free licence to use the Marks to provide Services for the Term of the Agreement and will indemnify Solhop for any losses, liabilities, claims, proceedings, damages, expenses (including without limitation legal expenses) Solhop may suffer as a result of any claim by a third party of infringement of its Intellectual Property Rights by Solhop's use of the Marks as permitted under the Agreement. 
</p><p>3.3 Upon termination or expiry of the Agreement the Licences set out in clauses 3.1 and 3.2 above shall immediately cease. 
</p><p>3.4 Partner Affiliate agrees to indemnify Solhop in respect of any losses, liabilities, claims, proceedings, damages, legal fees, expenses incurred as a result of continued use of the Services by Partner Affiliate after the Agreement has terminated or expired. 
</p><h2>4. Obligations of Solhop </h2>
<p>4.1 Solhop agrees: 
</p><p>4.1.1 to provide Partner Affiliate with access to a website of Solhop (the "Secured Website") via unique user ID and password to enable Partner Affiliate to monitor Service Bookings made using the Services and to access related management information. 
</p><p>4.1.2 to make available the Services to enable Customers to book online Transfer Services ; 
</p><p>4.1.3 to process all valid payments received from Transfer Providers; 
</p><p>4.1.4 to use reasonable endeavours in dealings with Consumers to facilitate Service Bookings; 
</p><p>4.1.5 to pay Commission to Partner Affiliate on Consumed Service Bookings in accordance with Clause 7; 
</p><p>4.1.6 to review, investigate and respond promptly and reasonably to complaints received by Customers (either direct or via the Partner Affiliate) regarding the Services, Transfer Services or Consumed Transfer Services and to inform Partner Affiliate of any appropriate response reasonably required by the Partner Affiliate; 
</p><p>4.1.7 to comply with all relevant applicable legislation, regulations and/or codes of practice in force from time to time (including but not limited to those relating to the advertising and/or sale of goods and/or services to Customers and Data Protection Legislation). 
</p><p>4.2 Solhop warrants that the Services shall be provided with reasonable skill and care and that Solhop shall use all reasonable endeavours to maintain operation of the Website and the Services. 
</p><h2>5. Obligations of Partner Affiliate </h2>
<p>5.1 Partner Affiliate agrees and acknowledges that the provision of Transfer Services is by various Transfer Providers for whom Solhop is a non-exclusive agent; 
</p><p>5.2 Partner Affiliate agrees, warrants and represents: 
</p><p>5.2.1 Partner Affiliate has all necessary rights in the Marks to grant the licence purportedly granted to Solhop in clause 3.2; 
</p><p>5.2.2 Partner Affiliate shall keep the user ID and password for the Secured Website confidential and secure and not disclose it to any person other than those who need to have access to the Secured Website. Partner Affiliate shall immediately notify Solhop of any suspected security breach or improper use; 
</p><p>5.2.3 Partner Affiliate will at its own cost integrate the Services on Partner Affiliate Website in the Locations; 
</p><p>5.2.4 not to communicate directly with Transfer Providers and not to act in a way which may affect Solhop's relationship with Transfer Providers in any way induce any Transfer Provider to terminate its contract with Solhop or any Customer; 
</p><p>5.2.5 to use best endeavours to maintain Partner Affiliate Website; 
</p><p>5.2.6 to inform Solhop of complaints by Customers regarding the Services, Transfer Services or Consumed Transfer Services and follow the instruction of Solhop on appropriate response; 
</p><p>5.2.7 it will not make any static copy of the Content or any part of the Website on the Partner Affiliate's own server; 
</p><p>5.2.8 to obtain the prior written consent of Solhop to use any of Solhop's Intellectual Property Rights or any Content on promotional material or Partner Affiliate Website (other than the Services as contemplated under the Agreement); 
</p><p>5.2.9 to be responsible for paying the appropriate taxes or dues and to maintain accurate records in compliance with Clause 9 below; 
</p><p>5.2.10 Partner Affiliate will comply with all relevant legislation, regulations and/or codes of practice in force from time to time (including but not limited to those relating to the advertising and/or sale of goods and/or services to Customers and Data Protection Legislation. 
</p><h2>6. Intellectual Property Rights </h2>
<p>6.1 The Parties agree that any Intellectual Property Rights in content or materials created or provided by one party to another, which subsist or may subsist, shall remain the property of the creating or providing party. As between the parties any goodwill arising from the use by one party of the other party's Intellectual Property Rights shall accrue to the other party. 
</p><p>6.2 In the event that the Partner Affiliate during the term of the Agreement licences or purchases a similar or identical domain name to top level domain names owned or used by Solhop, Partner Affiliate shall transfer, assign and register the same to Solhop through a domain name register company of Solhop's choice to be completed within 20 business days after purchase, effective date of licence or notification by Solhop. If Partner Affiliate does not effect the transfer within this timescale, Partner Affiliate hereby irrevocably and unconditionally authorises Solhop to sign and/or execute all documents that are necessary or useful in relation to or required for the assignment, registration, set over and transfer of the domain names to and in the name of Solhop. 
</p><h2>7. Payment Terms </h2>
<p>7.1 Solhop will provide Partner Affiliate with access to the Secured Website to enable Partner Affiliate to monitor the number of Service Bookings, Consumed Service Bookings and Commission. Solhop shall provide Partner Affiliate with a monthly credit invoice stating Commission payable that month for Consumed Service Bookings ("the Invoice"). 
</p><p>7.2 Solhop shall pay Commission to Partner Affiliate on a monthly basis, on the Payment Due Date unless the amount of Commission due to Partner Affiliate at that time is less than EUR 100. If it is less than EUR 100 Solhop will postpone payment until the month when 
Commission is EUR 100 or until the amount is claimed by Partner Affiliate after the termination of the Agreement. 
</p><p>7.3 All Commission shall be paid by direct bank transfer to the bank account specified by Partner Affiliate in the PARF or such other bank account as Partner Affiliate may notify to Solhop from time to time. 
</p><p>7.4 Partner Affiliate will produce a monthly statement from the information available on the Secured Website, which itemises the Consumed Service Bookings for which Commission is due to Partner Affiliate pursuant to the Invoice. If any item is disputed Partner Affiliate must give reasons at least 5 days before the Payment Due Date and pay the undisputed amount by the Payment Due Date. Items that are disputed shall be resolved in accordance with the procedure set out in clause 17.1. 
</p><h2>8. Commission </h2>
<p>8.1 Solhop will pay Commission to the Partner Affiliate for Consumed Service bookings is a percentage of the commission it receives from Transfer Providers. The Commission will be the Percentage Commission Split of the commission it receives from Transfer Providers for Consumed Service Bookings i.e. the Commission payable to the Partner Affiliate for each Consumed Service Booking will be the actual commission received by Solhop from a Transfer Provider in respect of such Consumed Service Booking multiplied by the applicable Percentage Commission Split in that month and divided by 100. Percentage Commission Split - 40% 
</p><h2>9. Taxes </h2>
<p>9.1 Partner Affiliate is responsible for properly accounting for its own taxes. 
</p><p>9.2 Partner Affiliate agrees to keep full, proper and up to date books of accounts and records identifying all Service Bookings and Commission received on Consumed Transfer Services and which will be available for inspection by Solhop or its authorised representatives on providing reasonable notice, taking such copies of documents as they require. 
</p><h2>10. Indemnity, Exclusions and Limitations </h2>
<p>10.1 The maximum liability of one party to the other for all claims made against such party under or in connection with the Agreement in a year shall not exceed the aggregate Commission received or paid by such party in the year preceding the event giving rise to such losses or EUR 10,000 (whichever is higher). 
</p><p>10.2 In no event shall either party be liable to the other party for any loss of production, loss of profit, loss of revenue, loss of contract, loss of damage to goodwill or reputation, loss of claim or any indirect, special, incidental or consequential damages or losses whether such damages are alleged as a result of a breach of contract, tort or otherwise. 
</p><p>10.3 The terms of the Agreement set out the full extent of Solhop's obligations and liabilities in respect of the supply of the Services. The Partner Affiliate shall have no remedy in respect of any untrue statement made to it upon which it relied in entering into the Agreement (unless such untrue statement was made knowing that it was untrue) other than any remedy it may have for breach of the express terms of the Agreement. Accordingly, any condition, warranty or other term concerning the supply of or failure to supply the Services which might but for this Clause 10.4 have effect between the parties or would otherwise be implied into or incorporated into the Agreement or any collateral contract (including without limitation the implied terms of satisfactory quality and fitness for purpose), whether by statute, common law or otherwise, is hereby excluded and Solhop shall not be liable to the Partner Affiliate in tort or otherwise than pursuant to the express terms of the Agreement in respect of the subject matter of the Agreement or the supply or non-supply of the Services. 
</p><p>10.4 Nothing in the Agreement shall exclude or limit either party's liability for the tort of deceit or for death or personal injury caused by its negligence. 
</p><p>10.5 The indemnities set out in the Agreement shall only apply provided that the Indemnified Party: a) Promptly notifies the Indemnifying Party in writing of the claim or suit; b) Makes no admissions or settlements without the Indemnifying Party's prior written consent; c) At the Indemnifying Party's request and expense, allows the Indemnifying Party complete control over any negotiations or litigation and/or the defence or settlement of such claim or suit; and d) Gives the Indemnifying Party all information and assistance as it may reasonably require. 
</p><h2>11. Termination </h2>
<p>11.1 The Agreement shall come into effect on the Commencement Date and subject to clauses 11.2 to 11.4 shall continue until terminated by either party on 30 days' written notice to the other party provided that such notice may not expire prior to the expiry of the Initial Period (if any). 
</p><p>11.2 Solhop may suspend or terminate the Agreement immediately without notice if Partner Affiliate offers Transfer Services to any third party or Customers for less than the Retail Price. 
</p><p>11.3 The Agreement shall terminate immediately without notice if: 
</p><p>11.3.1 the other party suspends or ceases trading or indicates that it intends to cease trading or becomes unable to pay its debts as they fall due; or 
</p><p>11.3.2 the other party has a receiver or liquidator appointed, or passes an effective resolution for winding-up (except for the purpose of amalgamation, reconstruction or reorganisation) or a Court makes an order to that effect or a similar event occurs; or 
</p><p>11.3.3 distress or execution is levied against the property of the other party; or 
</p><p>11.3.4 any licence, authorisation, consent or registration necessary to enable either party to comply with its obligations pursuant to the Agreement or to carry on business is revoked, or materially modified; 
</p><p>11.4 Either party may terminate the Agreement by service of immediate written notice on the other in the event that the other party commits a material breach of any term of the Agreement and (in the case of a breach capable of being remedied) shall have failed within 7 days after the receipt of a request in writing to do so, to remedy the breach (such request to contain a warning of the intention to terminate should the breach not be remedied). A Percentage Commission Split of 0% will apply to all Consumed Service Bookings during this period. 
</p><p>11.5 Any termination of the Agreement shall be without prejudice to any other rights or remedies and any rights or obligations of either party in respect of other Service Bookings made before the termination date will continue until discharged. 
</p><p>11.6 On termination all licences shall cease, Services will cease to be provided and the parties will return all Confidential Information. 
</p><h2>12. Force Majeure </h2>
<p>12.1 If either party's performance is affected by force majeure it will promptly notify the other and use reasonable endeavours to perform all obligations arising from the Agreement; 
<p>12.2 Neither party shall have any liability under or be deemed to be in breach of the Agreement for any delays or failures in performance of the Agreement which result from circumstances beyond the reasonable control of that party. If such circumstances continue for a continuous period of more than 14 days, either party may terminate the Agreement by written notice to the other party and the terms of clause 11.6 shall apply. 
</p><h2>13. Confidentiality </h2>
<p>13.1 Each party shall maintain the confidentiality of the other party's Confidential Information and shall not, without the prior written consent of the other, use, disclose, copy or modify the other party's Confidential Information (or permit others to do so) other than as necessary for the performance of its rights and obligations under the Agreement. 
<p>13.2 Each party undertakes to disclose the other party's Confidential Information only to those of its officers, employees, agents and contractors to whom, and to the extent to which, such disclosure is necessary for the purposes contemplated under the Agreement and to procure that such persons agree in writing to observe the obligations of confidentiality no less onerous than those set out in this Clause 13. 
</p><p>13.3 Each party shall give to the other notice of any misuse, theft, disclosure or other loss of the other party's Confidential Information. 
</p><p>13.4 The provisions of this Clause 13 shall not apply to information which: 
</p><p>13.4.1 is or comes into the public domain through no fault of the recipient, its officers, employees, agents or contractors; 
</p><p>13.4.2 is lawfully received by the recipient from a third party free of any obligation of confidence at the time of its disclosure; 
</p><p>13.4.3 is independently developed by the recipient, its officers, employees, agents or contractors; 
</p><p>13.4.4 is required by law, by court or governmental order to be disclosed. 
</p><h2>14. Complaints </h2>
<p>14.1 Any complaint from a Customer to Partner Affiliate shall be immediately referred to Solhop to decide in its absolute discretion on behalf of the Transfer Provider whether to admit or deny liability or pay compensation. 
</p><h2>15. Data Protection </h2>
</p><p>15.1 Each party will comply with its obligations under the Data Protection Legislation with regard to its activities relevant to the Agreement. 
</p><h2>16. Assignment </h2>
</p><p>16.1 Neither party may assign the benefit of the Agreement without the prior written consent of the other. 
</p><h2>17. Nothing in the Agreement shall be construed as meaning that Solhop and Partner Affiliate are legal partners nor that they are involved in a joint venture or relationship of principal and agent. Unless otherwise agreed in writing by Solhop or save as set out otherwise in the Agreement, Partner Affiliate shall not publish anywhere on Partner Affiliate Website(s) any statement, either express or implied that the Partner Affiliate Website is part of, or endorsed by, or an official Website of Solhop. </h2>
<p>17.1 No failure or delay in exercising any rights or remedies pursuant to the Agreement shall constitute a waiver of any right or remedy. Resort to one form of remedy shall not constitute a waiver of other available remedies; 
</p><p>17.2 A ruling by any court that one or more of the provisions contained in the Agreement is invalid, illegal, unenforceable in any respect shall not affect any other provision of the 
Agreement. Thereafter, the Agreement shall, subject to the ruling of the court, be construed as if the invalid illegal or unenforceable provision had never been included. 
</p><p>17.3 The Agreement constitutes the entire understanding of the parties with respect to the subject matter hereof and it supersedes all prior or contemporaneous oral or written Agreements, understanding or representations of any kind with respect hereto. Each of the parties agrees to do such acts and execute such documents in addition to the Agreement as may reasonably be required in order to give effect to the terms of the Agreement 
</p><p>17.4 Any variations of the Agreement shall be agreed in writing and signed by both parties. In the event of any conflict between these terms and conditions and the PARF the terms of the PARF shall prevail. 
</p><p>17.5 Unless expressly provided in the Agreement, none of the terms of the Agreement is enforceable or intended to be enforceable pursuant to the Contracts (Rights of Third Parties) Act 1999 by any party or person who is not a party to the Agreement. 
</p><p>17.6 The Partner Affiliate shall not, either during or within six months after the termination or expiry of the Agreement engage, employ or otherwise solicit for employment either for themselves or for any other person, firm or company any person who was engaged by the other party in providing the Services. 
</p><p>17.7 The construction and performance and validity of the Agreement shall in all respects be governed by Spanish Law and the parties submit to the non-exclusive jurisdiction of the SpanishnCourts. 
</p>
        </Container>
      </Dialog>
    </span>
  );
}