import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';

const getId = (name, permissions) => {
    if (!permissions) {
        return false;
    }
    if (name === 'partner' && permissions.partner_id) {
        return permissions.partner_id;
    }
    if (name === 'user') {
        return permissions.id;
    }
    return false;
};

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const { permissions } = usePermissions();
    
    return (
        <div>
            {resources.map(resource => {
                let displayed = true;
                let href = "";
                let id = getId(resource.name, permissions);
                if (resource.hasList) {
                    href = `/${resource.name}`;
                } else if (resource.hasShow && id) {
                    href = `/${resource.name}/${id}/show`;
                } else if (resource.hasEdit && id) {
                    href = `/${resource.name}/${id}`;
                } else if (resource.hasCreate) {
                    href = `/${resource.name}/create`;
                } else {
                    displayed = false;
                }
                return (
                    displayed ?
                    <MenuItemLink
                        key={resource.name}
                        to={href}
                        primaryText={
                            (resource.options && resource.options.label) ||
                            resource.name.charAt(0).toUpperCase() + resource.name.slice(1) + 's'
                        }
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    /> : null
                )
            })}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;