import Config from '../../config'
import style from "../../index.css";
const authorizationToken = "Bearer " + localStorage.getItem('token')

export const configFroala = {
    attribution: false,
 
    tableStyles: {
        class1: "Transparent",
        class2: "Center",
        class3: "Width 80%",
        class4: "Width 60%",
        class5: "Width 40%",
    },

    toolbarButtons: {
        moreText: {
            buttons: [
                "fontSize",
                "bold",
                "italic",
                "underline",
                "strikeThrough",
                "subscript",
                "superscript",
                "fontFamily",
                "textColor",
                "backgroundColor",
                "inlineClass",
                "inlineStyle",
                "clearFormatting",
            ],
        },

        moreParagraph: {
            buttons: [
                "alert",
                "alignLeft",
                "alignCenter",
                "formatOLSimple",
                "alignRight",
                "alignJustify",
                "formatOL",
                "formatUL",
                "paragraphFormat",
                "paragraphStyle",
                "lineHeight",
                "outdent",
                "indent",
                "quote",
            ],
        },
        moreRich: {
            buttons: [
                "insertImage",
                "insertVideo",
                "insertTable",
                "insertLink",
                "emoticons",
                "fontAwesome",
                "specialCharacters",
                "embedly",
                // "insertFile",
                "insertHR",
            ],
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            align: "right",
            buttonsVisible: 2,
        },
    },

    pluginsEnabled: [
        "table",
        "spell",
        "quote",
        "save",
        "quickInsert",
        "paragraphFormat",
        "paragraphStyle",
        "help",
        "draggable",
        "align",
        "link",
        "lists",
        "file",
        "image",
        "emoticons",
        "url",
        "video",
        "embedly",
        "colors",
        "entities",
        "inlineClass",
        "inlineStyle",
        "fontSize",
        "imageTUI",
        "lineHeight",
    ],
    lineHeights: {
        Default: '',
        Single: '1',
        '0': '0',
        '1.5': '1.5',
        Double: '2'
      },

    // imageUploadURL: 'http://i.froala.com/upload',
    // imageUploadURL: 'https://api-solhop.staging.rndx2.org/api/media/create_media',
    imageUploadURL: Config.api_url+'/media/create_media',
    imageUploadParam: 'media',

    imageOutputSize: true,
    requestHeaders: {
        Authorization: authorizationToken
    },

    imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
    imageMaxSize: 1024 * 1024 * 3,
    videoUpload: false

};