import React from 'react';
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LogoMain from './LogoMain';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
    useLogin,
    useRedirect,
    useNotify,
    Notification,
    TextInput,
    PasswordInput,
    SimpleForm,
    Toolbar,
    MenuItemLink
} from 'react-admin';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.solhop.com/">
                Solhop
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        backgroundColor: 'whitesmoke'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    toolbar: {
        paddingLeft: '16px',
        paddingRight: '16px'
    }
}));

const validateLogin = (values) => {
    const errors = {};
    if (!values.username) {
        errors.username = ['The username is required'];
    }
    if (!values.password) {
        errors.password = ['The password is required'];
    }
    return errors
};
export default function Login() {
    const redirect = useRedirect();
    const token = localStorage.getItem('token');
    if ( token && token !== 'undefined' && typeof token !== 'undefined' ) {
        redirect('/conversion');
    }
    const classes = useStyles();
    const [username, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const submit = (e) => {
        e.preventDefault();
        login({ username, password })
            .catch(() => notify('Invalid email or password'));
    };
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>

                <LogoMain />

                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <SimpleForm
                className={classes.form}
                validate={validateLogin}
                toolbar={(
                    <Toolbar className={classes.toolbar}>
                      <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Sign In
                      </Button>
                    </Toolbar>
                  )}
                onSubmit={submit}>

                    <TextInput
                        variant="outlined"
                        id="username"
                        margin="normal"
                        fullWidth
                        helperText=""
                        label="Email Address"
                        name="username"
                        value={username}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <PasswordInput
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        helperText=""
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        name="password"
                        label="Password"
                    />
                    
                    <Grid container>
                        <Grid item xs>
                            <MenuItemLink
                                to="/reset-password"
                                primaryText="Forgot Password?"
                            />
                        </Grid>
                    </Grid>

                </SimpleForm>
                <Notification />
                <MenuItemLink
                    className="registerLink"
                    to="/register"
                    primaryText="Registration"
                />
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}