import React from 'react';
import { useState } from 'react';
import { format, parse } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useField } from 'react-final-form';
import { Button, Typography, Container, Toolbar, TextField, Dialog, Slide, useMediaQuery, useTheme, Box } from '@material-ui/core';

const parseVal = (val) => {
    let defStart = null;
    let defEnd = null;

    if (!val) {
        return [
            {
            startDate: defStart,
            endDate: defEnd,
            key: 'selection'
            }
        ]
    }
    let dates = val.split("_");
    if (dates[0] && dates[1]) {
        let start = parse(dates[0], 'yyyy-MM-dd', new Date());
        let end = parse(dates[1], 'yyyy-MM-dd', new Date());
        return [
            {
            startDate: start,
            endDate: end,
            key: 'selection'
            }
        ];
    }
    return [
        {
        startDate: defStart,
        endDate: defEnd,
        key: 'selection'
        }
    ];
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
const DateRangeInput = (props) => {
    const {
        source
    } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const {
        input: { onChange, value },
    } = useField(source);

    const [state, setState] = useState(parseVal(value));

    const getValue = (selections) => {
        if (!selections.startDate || !selections.endDate) {
            return "";
        }
        let start = format(selections.startDate, 'yyyy-MM-dd');
        let end = format(selections.endDate, 'yyyy-MM-dd');
        return start + '_' + end;
    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (e) => {
      e.preventDefault();
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    return (
        <Box component="span" mr={2} style={{paddingBottom: '14px'}}>
            <TextField value={value} name={source} type="hidden" />
            <Button onClick={handleClickOpen} variant="contained">
                {value ? value : 'Select date range'}
            </Button>
            <Dialog  fullScreen={fullScreen} maxWidth="lg" open={open} onClose={handleClose} TransitionComponent={Transition}>
                <Container>
                <Typography variant="h6">Select date range</Typography>
                    <DateRangePicker
                        onChange={item => {
                            setState([item.selection]);
                            onChange(getValue(item.selection));
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={state}
                        direction="horizontal"
                        />
                </Container>
                <Toolbar>
                    <Button
                        color="secondary"
                        variant="contained" 
                        onClick={() => {
                            setState([{startDate: null, endDate: null, key: 'selection'}])
                            onChange(null);
                            handleClose();
                         }
                        }>
                        Clear dates
                    </Button>
                    <Button
                        style={{marginLeft: '30px'}}
                        autoFocus
                        variant="contained"
                        color="primary"
                        onClick={handleClose}>
                    Close
                    </Button>
                </Toolbar>
            </Dialog>
        </Box>
    );
};

export default DateRangeInput;