import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  Filter,
  TextInput,
} from "react-admin";

import { TextFieldCustom } from "./text-field-custom";
import { BooleanNumField } from './boolean-field-custom'

const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search Name" source="nombre" alwaysOn />
    <TextInput label="Id" source="id" alwaysOn />
  </Filter>
);

const CustomTextField = (props) => {
  const originalTipoDestino = props.record.tipo_destino
  const record = {}
  switch (originalTipoDestino) {
    case "airport":
      record.tipo_destino = "Airport"
      break;
    case "resort":
      record.tipo_destino = "Resort"
      break;
    case "cruise_port":
      record.tipo_destino = "Cruise port"
      break;
    case "train_station":
      record.tipo_destino = "Train station"
      break;
    case "hotel":
      record.tipo_destino = "Hotel"
      break;
    default:
      record.tipo_destino = ""
  }

  let newProps = { ...props, record }
  return <TextField {...newProps} />
}

export const LocationList = ({ permissions, ...props }) => (
  <List {...props} filters={<PostFilter />} bulkActionButtons={false}  >
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="nombre" label="Name" />
      {/* <TextField source="province.id" label="province.id" /> */}
      <TextField source="province.nombre" label="Province" sortable={false} />
      <CustomTextField source="tipo_destino" label="Destination type" />
      <BooleanNumField source="popular" />
      <BooleanNumField source="popular_shortlist" label="Popular (short)" />
      <TextFieldCustom source="seo_descripcion_de" source="seo_descripcion_en" label="SEO" sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
);
