import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    IconButton,
    Icon,
    Link
} from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Config from '../config';

const useStyles = makeStyles({
    code: {
      backgroundColor: "#EEEEEE",
      fontFamily: "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New"
    },
    link: {
        display: "block"
    }
});

const CodeField = ({ source, record = {} }) => {
    const classes = useStyles();
    const code = '<script class="solhop-search-widget-020000" data-ref="' +
            record.reference +
            '" async src="' + Config.main_url + '/widget.js"></script>';
    const copy = () => {
        navigator.clipboard.writeText(code);
    }
    const docs = Config.main_url + '/widget-docs.php';
    
    return record ? (
            <span>
                <span className={classes.code}>
                    {code}
                </span>
                <IconButton size="small" onClick={copy}>
                    <Icon component={FileCopyOutlinedIcon} />
                </IconButton>
                <Link fontSize="small" className={classes.link} href={docs} target="_blank">
                     Widget Docs
                </Link>
            </span>
    ) : null;
};

CodeField.defaultProps = {
    addLabel: true
};
CodeField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired
};

export default CodeField;