import React from 'react';
import { AppBar } from 'react-admin';
import SolhopUserMenu from './SolhopUserMenu';
import Logo from './Logo';
import Box from '@material-ui/core/Box';

const SolhopAppbar = props => {
    return (
        // <Box >
           <AppBar {...props} userMenu={<SolhopUserMenu />}>
                <Logo />
            </AppBar> 
        // </Box>
            
    );
};
export default SolhopAppbar;