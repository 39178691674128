import * as React from "react";
import Config from '../../config'
import { createMediaApi } from "./create-media-api";
import {
  TabbedForm,
  FormTab,
  Create,
  SaveButton,
  Toolbar,
  TopToolbar,
  ListButton,
  ImageInput,
  ImageField,
  TextInput,
  required,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

import ChevronLeft from "@material-ui/icons/ChevronLeft";

const EditActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
  </TopToolbar>
);

const EditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const transform = (data) => {
  if (!data.url.rawFile) {
    return data
  }
  const imageUploadURL = Config.api_url + '/media/create_media'
  return createMediaApi(data, imageUploadURL).then((rec) => {
    let url = rec.data.link
    let name = rec.data.name
    const newDdata = { ...data, url, name }
    return {
      ...newDdata
    };
  })
};

export const GalleryCreate = (props) => {

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({data}) => {
    notify(`Changes saved`);
    redirect(`/gallery/${data.id}/show`);
    refresh();
  };

  return (
    <Create
      actions={<EditActions />}
      transform={transform}
      onSuccess={onSuccess}
      {...props}
    >
      <TabbedForm toolbar={<EditToolbar />} >

        <FormTab label="Image" >
          <ImageInput source="url" label="Related pictures" accept="image/*" validate={required()}>
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Key" >
          <TextInput source="key" validate={required()} />
        </FormTab>

      </TabbedForm>
    </Create>
  );
};
