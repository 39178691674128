import * as React from "react";
import { useState, useEffect } from 'react';
import {
  useNotify,
  useRefresh,
  useRedirect,
  TabbedForm,
  FormTab,
  Edit,
  ShowButton,
  SaveButton,
  Toolbar,
  ReferenceInput,
  AutocompleteInput,
  TopToolbar,
  ListButton
} from "react-admin";


import { Froala } from "../../components/froala/froala";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

const EditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);


const EditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);


export const PageContentEdit = ({ ...props }) => {
  const [currentLabel, setCurrentLabel] = useState("loading");

  //automatic transition from the editing page to the viewing page
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const idRegExpColumn = /\/[0-9]{1,}\/([0-9])$/;

  const transform = (data) => {
    const froalaAdvertisingText = '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>'

    const en = data.en.replace(froalaAdvertisingText, '')
    const de = data.de.replace(froalaAdvertisingText, '')
    const es = data.es.replace(froalaAdvertisingText, '')
    const newDdata = { ...data, en, de, es }

    return {
      ...newDdata

    };

  }

  const onSuccess = () => {
    const columnPath = window.location.href.match(idRegExpColumn) || false;
    const idColumn = columnPath ? columnPath[1] : "";
    const path = props.match.url + "/show" + "/" + (idColumn);

    notify(`Changes saved`);
    redirect("list", path);
    refresh();
  };

  //getting the current province
  const AutocompleteInputDefaultValue = ({ optionText, ...rest }) => {
    let label = "loading"
    let { choices, input } = rest;
    let currentLocationId = input.value

    if (typeof choices != undefined && choices != null && choices.length > 0) {
      choices.forEach(element => {
        if (element.hasOwnProperty('id') && (element.id == currentLocationId)) {
          label = element.nombre.substring(0, 30)
        }
      });
    }

    //removes elements with the same keys
    choices = Array.from(new Set(choices))
    const uniqueRest = { ...rest, choices }


    useEffect(() => {
      setCurrentLabel(label);
    });
    return <AutocompleteInput optionText={optionText}  {...uniqueRest} />
  }


  return (
    <Edit
      actions={<EditActions />}
      onSuccess={onSuccess}
      undoable={false}
      transform={transform}
      {...props}
    >
      <TabbedForm toolbar={<EditToolbar />} >

        <FormTab label="Location_ID" >
          <ReferenceInput label={currentLabel} source="location_id" reference="location"
            filterToQuery={searchText => ({ nombre: searchText })}
            sort={{ field: 'popular', order: 'DESC' }}
          >
            <AutocompleteInputDefaultValue optionText="nombre" optionValue="id" />
          </ReferenceInput>
        </FormTab>

        <FormTab label="English" >
          <Froala source="en" />
        </FormTab>

        <FormTab label="Deutsch">
          <Froala source="de" />
        </FormTab>

        <FormTab label="Español">
          <Froala source="es" />
        </FormTab>

      </TabbedForm>
    </Edit>
  );
};
