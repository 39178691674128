import jsonapiClient from 'ra-jsonapi-client';
import { GET_ONE, UPDATE, DELETE } from "react-admin";
import Config from './config';


const handleUserProfile = dataProvider => (verb, resource, params) => {
        if (resource === "profile") {
            if (verb === GET_ONE) {
                const storedProfile = localStorage.getItem("permissions");
                if (storedProfile) {
                    let profile = JSON.parse(storedProfile);
                    profile.id = params.id;
                    return Promise.resolve({
                        data: profile
                    });
                }

                return Promise.resolve({
                    data: params
                });
            }
            if (verb === UPDATE) {
                localStorage.setItem("permissions", JSON.stringify(params.data));
            }
            if (verb === DELETE) {
                console.log(resource, verb, resource, params);
                return Promise.resolve({data: {message: "Access denied"}});
            }
        }
        return dataProvider(verb, resource, params);
    };

export default handleUserProfile(
    jsonapiClient(Config.api_url)
);