import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
  ReferenceField,
  DeleteButton,
  AutocompleteInput,
  Filter,
  ReferenceInput,
} from "react-admin";

import { TextFieldCustom } from "./text-field-custom";

const PostFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="location_id" reference="location" alwaysOn
      filterToQuery={searchText => ({ nombre: searchText })}
      sort={{ field: 'popular', order: 'DESC' }}
    >
      <AutocompleteInput
        resettable={true}
        optionText="nombre"
      />
    </ReferenceInput>
  </Filter>
);


export const PageContentList = (props) => (

  <List {...props} filters={<PostFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <ReferenceField label="Location_ID" source="location_id" reference="location" >
        <TextField source="nombre" />
      </ReferenceField>
      <TextFieldCustom source="en" label="EN" sortable={false}/>
      <TextFieldCustom source="de" label="DE" sortable={false}/>
      <TextFieldCustom source="es" label="ES" sortable={false}/>
      <ShowButton />
      <EditButton />
      <DeleteButton undoable={false}/>
    </Datagrid>
  </List>
);
