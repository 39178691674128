export function isSuper(permissions) {
    if (!permissions) {
        return false;
    }
    return permissions.role.includes('super-admin');
}
export function isAdmin(permissions) {
    if (!permissions) {
        return false;
    }
    return permissions.role.includes('super-admin') ||
            permissions.role.includes('admin') ;
}
export function isAll(permissions) {
    if (!permissions) {
        return false;
    }
    return permissions.role.includes('super-admin') ||
            permissions.role.includes('admin') ||
            permissions.role.includes('partner') ;
}
export function isPartner(permissions) {
    if (!permissions) {
        return false;
    }
    return permissions.role.includes('partner') ;
}