import axios from "axios";

export async function createMediaApi(data, imageUploadURL) {
  let files = (data.url.rawFile)
  const formData = new FormData()
  formData.append('media', files)
  const authorizationToken = "Bearer " + localStorage.getItem('token')

  return axios.post(imageUploadURL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': authorizationToken
    }
  })
    .then((rec) => {
      return rec
    })
    .catch(err => {
      console.log('upload fail', err)
    })
}