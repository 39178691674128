import React from 'react';
import PropTypes from 'prop-types';


const StatusField = ({ source, record = {} }) => {
    
    const opt = {
        'pendiente_conductor': 'pending driver',
        'asignado_conductor': 'assigned driver',
        'confirmado_conductor': 'confirmed driver',
        'completado': 'completed',
        'cancelado': 'canceled'
    };
    
    const color = {
        'pendiente_conductor': '#c3c3c3',
        'asignado_conductor': '#999fce',
        'confirmado_conductor': '#00adf3',
        'completado': '#29b719',
        'cancelado': '#ea5375'
    };
    
    const status = opt[record.transfer_status];
    
    return record ? (
        <span style={{
            color: color[record.transfer_status],
            textAlign: 'center',
            display: 'block'
        }}>
            {status}
        </span>
    ) : null;
};

StatusField.defaultProps = {
    addLabel: true
};
StatusField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired
};

export default StatusField;