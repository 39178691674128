import * as React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    logo: {
        width:'250px',
        textAlign: 'center',
        maxWidth: '100%',
    },
    img: {
        display: "inline-block",
        maxWidth: '100%',
        height: 'auto'
    }
});
const LogoMain = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.logo}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1326.24" height="339.05" viewBox="0 0 1326.24 339.05" className={classes.img}>
            <g>
                <path d="M319.85,178.6c-35.08-1.36-215.78-28.62-218.12-29C60.26,145.92-7.78,186.53,9,212.27c32.71,50,49.71,67.39,86.08,114.77,9.54,12.46,26.1,15.7,37,7.58l156-117.36L302.81,206a89.52,89.52,0,0,0,23.29-27.55C324.18,178.52,322.54,178.72,319.85,178.6ZM152,193.6l-17.36-6.16L180,166.14l57.17,5.44Z" transform="translate(0 -0.03)" fill="#3f51b5"/>
                <path d="M268.88,59.52a21.88,21.88,0,1,0,.12,0Z" transform="translate(0 -0.03)" fill="none"/>
                <path d="M294.92,112.14c-9.51-3.87-15.79-7.37-19.73-10.85a21.68,21.68,0,0,1-5.18.81A21.34,21.34,0,1,1,283.36,97c15.66,10.47,34.9,18,54.92,23.14A128.29,128.29,0,0,0,317,57.93h.07a162.85,162.85,0,0,0-21.45-25C255.33-3.94,197.87-11.41,158.78,18.61L144.09,29.84,0,139.1c-.48.4,63.67-3.62,115.8,4.08l-12.52-21.07,24,1.25,17.12,19.43c6.43.09,12.54.24,17.33.54,5.19.37,14.83.58,26,.87L124.81,89.57,164,95l80.26,51c31.42,1.48,61,3.93,67.91,9,5.6,4.17,13.31,8.87,18.71,13a107.88,107.88,0,0,0,7.6-42.1A335.3,335.3,0,0,1,294.92,112.14Z" transform="translate(0 -0.03)" fill="#2196f3"/>
                <path d="M535.89,216.39q-18-17.59-13.16-55.21Q538.53,95.6,620.51,84q46.79-1.8,67.09,21.36,28.57,39.25-4.66,91.46-36.11,46.79-102.29,40.47Q553.88,234,535.89,216.39ZM645,126.72q-8.43-7.66-21.21-8.27-19.41-1-33.4,11.28T573,161.47q-5.87,38.5,35.35,40.17,19.39-.75,32.49-15.64t13.24-38.81Q653.45,134.41,645,126.72Z" transform="translate(0 -0.03)" fill="#3f51b5"/>
                <path d="M744.91,36.48h50.24L757.69,185.55a9.15,9.15,0,0,0,1.88,6.92,7.77,7.77,0,0,0,6.54,3h27.38L782.81,238h-34.3q-20.76,0-33-15.79t-8-36.7Z" transform="translate(0 -0.03)" fill="#3f51b5"/>
                <path d="M845.55,36.48h50.24L884.36,83.41h43.17q20.76,0,32.87,15.72t7.6,36.78L943.18,238.05H892.94l24.82-102.14A8.69,8.69,0,0,0,916,129a7.83,7.83,0,0,0-6.54-3H874L847.66,238.06H797.42Z" transform="translate(0 -0.03)" fill="#2196f3"/>
                <path d="M988.38,216.39q-18-17.59-13.16-55.21Q991,95.6,1073,84q46.79-1.8,67.09,21.36,28.58,39.25-4.66,91.46-36.11,46.79-102.29,40.47Q1006.36,234,988.38,216.39Zm109.14-89.67q-8.43-7.66-21.21-8.27-19.39-1-33.4,11.28t-17.45,31.74q-5.87,38.5,35.35,40.17,19.39-.75,32.49-15.64t13.24-38.81Q1105.94,134.41,1097.52,126.72Z" transform="translate(0 -0.03)" fill="#2196f3"/>
                <path d="M1169.72,138.17h47.56l-14,57.46h30.24q32.19.45,42.72-35.5,2.85-13.84-3.69-24.82T1247.65,124h-74.47l10.1-40.31h69.65q32.94,0,53,16.1t20.31,41.37q-1.82,44.09-29.71,69.87t-68.82,27.45h-35.65l-14.14,56.86h-48.29Z" transform="translate(0 -0.03)" fill="#2196f3"/>
                <path d="M464.67,139.07l-20,.6a38.37,38.37,0,0,1-9.27-.44q-2.19.09-4.62.09c-17.63,0-38.29-2.8-53.16-5.28q-1.17,18.9,8.76,30.59,12.25,14.44,35.43,14.89h22.26q14.9-.9,14.59,8.88-2.57,8.88-15.19,7.82H364.18L355.61,238H445q52.5,1.65,61.83-43.17,4.21-25-4.89-39.94T464.67,139.07Z" transform="translate(0 -0.03)" fill="#3f51b5"/>
                <path d="M446.28,84q-54.15-3.17-67.54,40.77c-.17.94-.32,1.86-.46,2.77a336.89,336.89,0,0,0,50,2.43q1.79-6.22,10.45-5.8h82L529.61,84Z" transform="translate(0 -0.03)" fill="#3f51b5"/>
                <path d="M332.82,85.72c12.56,1.38,26.33,3.56,40.43,6.43,6.91,1.41,13.67,2.94,20.2,4.56a15.84,15.84,0,0,1,6.32-5.91c-8-2.08-16.43-4-25.07-5.79-15.29-3.12-30.16-5.42-43.6-6.8a14.89,14.89,0,0,1,1.75,6.94C332.85,85.34,332.82,85.53,332.82,85.72Z" transform="translate(0 -0.03)" fill="#2196f3"/>
                <path d="M443.28,127a120.21,120.21,0,0,1-16.65.58c-17.61-.42-40.31-3.3-63.92-8.11s-45.62-11-62-17.55c-19.12-7.6-23.59-12.91-23.66-14.08.35-.72,3-2.26,10.14-3.15a14.9,14.9,0,0,1-2.44-7c-11.72,1.73-14.18,5.57-14.82,8.7-.88,4.33.92,11.31,26.66,21.77,16.91,6.88,39.88,13.45,64.66,18.5,20,4.09,48.21,8.37,69.54,8.37a104.46,104.46,0,0,0,14.36-.87A14.82,14.82,0,0,1,443.28,127Z" transform="translate(0 -0.03)" fill="#2196f3"/>
            </g>
            </svg>
        </div>
    );
};

export default LogoMain;