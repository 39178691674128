import React from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    SaveButton,
    PasswordInput,
    useRedirect,
    SimpleForm,
    minLength,
    maxLength,
    email,
    required
} from 'react-admin';

const validateName = [required(), minLength(2), maxLength(36)];
const validateEmail = [required(), email()];
const validatePass = [minLength(6), maxLength(36)];

const ProfileEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ProfileEdit = ({ staticContext, ...props }) => {

    const id = localStorage.getItem('current');
    const redirect = useRedirect();
    if (!id) {
        redirect('/login');
    }

    return (
            <Edit
                id={id}
                basePath="/profile"
                resource="profile"
                title="Profile"
                {...props}
            >
                <SimpleForm toolbar={ < ProfileEditToolbar / > }>
                    <TextInput source="name" validate={validateName} />
                    <TextInput source="email" validate={validateEmail} />
                    <PasswordInput validate={validatePass} source="new_password" />
                </SimpleForm>
            </Edit>
    );
};
export default ProfileEdit;
