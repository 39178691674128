import * as React from "react";
import { Create, required, FormTab, TabbedForm, ReferenceInput, AutocompleteInput } from "react-admin";
import { Froala } from "../../components/froala/froala";


export const PageContentCreate = ({ ...props }) => {

  const transform = (data) => {
    const froalaAdvertisingText = '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>'

    const en = data.en.replace(froalaAdvertisingText, '')
    const de = data.de.replace(froalaAdvertisingText, '')
    const es = data.es.replace(froalaAdvertisingText, '')
    const newDdata = { ...data, en, de, es }

    return {
      ...newDdata

    };
  }





  return (
    <Create title="New Location_ID" {...props}  transform={transform}>

      <TabbedForm >
        <FormTab label="Location_ID" >
          <ReferenceInput label="location" source="location_id" reference="location"
            filterToQuery={searchText => ({ nombre: searchText })}
            sort={{ field: 'popular', order: 'DESC' }}
          >
            <AutocompleteInput optionText="nombre" optionValue="id" validate={required()} />
          </ReferenceInput>
        </FormTab>

        <FormTab label="English" >
          <Froala source="en" validate={required()} />
        </FormTab>

        <FormTab label="Deutsch">
          <Froala source="de" />
        </FormTab>

        <FormTab label="Español">
          <Froala source="es" />
        </FormTab>
      </TabbedForm>

    </Create>
  );
};
