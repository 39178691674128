import React from "react";

import FroalaEditor from "react-froala-wysiwyg";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/third_party/embedly.min.css";

import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/embedly.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/line_height.min.js";

import { useInput, required } from "react-admin";

import { configFroala } from "./config";


const FroalaWrapper = ({ value, onChange }) => {
  const visibleModel = typeof value === "object" ? "" : value;
  return (
    <FroalaEditor
      model={visibleModel}
      config={configFroala}
      onModelChange={onChange}
    />
  );
};
//using Froala as a component of the input to the reactAdmin
const OwnInputComponent = (props) => {

  const {
    input: { onChange, ...rest },
  } = useInput(props);
  return <FroalaWrapper onChange={onChange} {...rest} />;
};

export const Froala = ({ source }) => {
  return <OwnInputComponent source={source} validate={required()} />;
};
