import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from 'react-admin';

export const TextFieldCustom = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  let visibleText = "";

  if (typeof record[source] === "string") {
    //removes html tags 
    const matches = record[source].replace(/<\/?[^>]+(>|$)/g, "").replace(/\&[a-z]+\;/g, " ")
    visibleText = matches.substring(0, 35)
  }

  return <span>{visibleText}</span>;
};

TextFieldCustom.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
