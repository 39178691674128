import * as React from "react";
import {
    useAuthenticated,
    useRedirect,
    useLogin,
    useLogout
} from 'react-admin';
import queryString from 'query-string';
import Container from '@material-ui/core/Container';

const SetPass = ({ staticContext, ...props }) => {
        const logout = useLogout();
        logout();
        useAuthenticated({ role: 'guest' });
        
        const redirect = useRedirect();
        const params = queryString.parse(props.location.search);
        
        let redirPath = typeof params.r !== 'undefined' ?  '/' + params.r : '/profile';
        
        const username = params.m;
        const password = params.c;
        
        const login = useLogin();
        
        login({ username, password })
            .catch(() => {
                redirect('/login');
            })
            .finally(() => {
                redirect(redirPath);
            });
    return (
            <Container>Redirects... Please wait...</Container>
    );
};

export default SetPass;
