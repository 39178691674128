import * as React from "react";
import PropTypes from "prop-types";
import { useRecordContext } from 'react-admin';

import style from "../../index.css";

export const TextFieldCustom = (props) => {

  const { source } = props;
  const record = useRecordContext(props);
  const language = ["ES", "EN", "DE"]
  const seo = {
    ES: ["seo_descripcion_es", "seo_tags_es", "seo_titulo_es"],
    EN: ["seo_descripcion_en", "seo_tags_en", "seo_titulo_en"],
    DE: ["seo_descripcion_de", "seo_tags_de", "seo_titulo_de"],
  }


  const seoSearch = (lang) => {
    const arr = seo[lang]
    let flag = true
    arr.forEach(element => {
      if (!record[element]) flag = false
    });
    return flag
  }

  const listItems = language.map((item) => {

    const clazz = seoSearch(item) ?
      "seo contains-seo" :
      "seo"
    return (
      <div className={clazz} key={item}>
        {item}
      </div>
    )
  });

  return (
    <div style={{ display: "flex" }}>
      {listItems}
    </div>
  )
};

TextFieldCustom.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};
