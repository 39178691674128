import * as React from "react";

import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { cloneElement } from 'react';
import {
  List,
  EditButton,
  ShowButton,
  CreateButton,
  DeleteButton,
  useListContext,
  TextInput,
  Filter,
  TopToolbar,
} from "react-admin";


const useStyles = makeStyles({
  root: {
    marginTop: '1em',
  },
  media: {
    height: 140,
  },
  title: {
    paddingBottom: '0.5em',
  },
  actionSpacer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
});


const PostFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Key" source="key" alwaysOn />
  </Filter>
);

const GalleryGrid = (props) => {
  const data = props.data
  const classes = useStyles();
  const { ids } = useListContext();
  return ids ? (
    <Grid container spacing={2} className={classes.root}>

      {ids.map(id => (
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
          <Card>
            <CardMedia
              image={data[id]["url"]}
              className={classes.media}
            />
            <CardContent className={classes.title}>
              <Typography
                variant="subtitle1"
                align="center"
              >
                {data[id]["key"]}
              </Typography>
            </CardContent>
            <CardActions
              classes={{ spacing: classes.actionSpacer }}
            >
              <ShowButton
                basePath="/gallery"
                record={data[id]} />
              <EditButton
                basePath="/gallery"
                record={data[id]}
              />
               <DeleteButton
                basePath="/gallery"
                record={data[id]}
               />
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : null;
}
  ;


const ListActions = (props) => (
  <TopToolbar>
    {cloneElement(props.filters, { context: 'button' })}
    <CreateButton />
  </TopToolbar>
);

export const GalleryList = (props) => (
  <List
    {...props}
    // sort={{ field: 'key', order: 'ASC' }}
    filters={<PostFilter />}
    actions={<ListActions />}
  >
    <GalleryGrid />
  </List>
);


