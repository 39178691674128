import * as React from "react";
import Config from '../../config'
import { createMediaApi } from "./create-media-api";

import {
  TabbedForm,
  FormTab,
  Edit,
  ShowButton,
  SaveButton,
  DeleteButton,
  Toolbar,
  ListButton,
  ImageInput,
  ImageField,
  TextInput,
  required,
  TopToolbar,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { makeStyles } from '@material-ui/core/styles';

const EditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    <ShowButton basePath={basePath} record={data} />
    <DeleteButton basePath="/gallery" record={data} />
  </TopToolbar>
);

const EditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const useStyles = makeStyles({
  imageField: {
    '& img': {
      objectFit: "contain",
      minWidth: 'initial',
      maxWidth: '42em',
      maxHeight: '15em',
    }
  },
});

const transform = (data) => {
  if (!data.url.rawFile) {
    return data
  }
  const imageUploadURL = Config.api_url + `/media/update_media/${data.key}`
  return createMediaApi(data, imageUploadURL).then((rec) => {
    let url = typeof rec === 'object' ? rec.data.link : undefined
    const newDdata = { ...data, url }
    return {
      ...newDdata
    };
  })
};

export const GalleryEdit = (props) => {

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles();

  const onSuccess = () => {
    const path = props.match.url + "/show";
    notify(`Changes saved`);
    redirect(path);
    refresh();
  };

  return (
    <Edit
      undoable={false}
      actions={<EditActions />}
      transform={transform}
      onSuccess={onSuccess}
      {...props}
    >
      <TabbedForm toolbar={<EditToolbar />} >

        <FormTab label="Image" >
          <ImageField source="url" title="title" className={classes.imageField} />
          <ImageInput source="url" label="Related pictures" accept="image/*">
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>

        <FormTab label="Key" >
          <TextInput source="key" validate={required()} />
        </FormTab>

      </TabbedForm>
    </Edit>
  );
};


