import env from "@beam-australia/react-env";

const Config = {
    api_url: env("API_URL").concat(env("API_PATH")),
    web_url: env("API_URL"),
    client_id: env("CLIENT_ID"),
    client_secret: env("CLIENT_SECRET"),
    api_url_token: env("API_URL").concat(env("API_PATH_TOKEN")),
    main_url: env("MAIN_URL"),
};

export default Config;