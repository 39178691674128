import React from "react";

import Typography from '@material-ui/core/Typography'

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { BooleanNumField } from './boolean-field-custom'

import { Show, SimpleShowLayout, TabbedShowLayout, Tab, TextField } from "react-admin";


const PostTitle = ({ record }) => {
  return <span>{record.location_id}</span>;
};

export const LocationShow = (props) => {
  return (
    <Show title={<PostTitle />} {...props}>

      <SimpleShowLayout>
        <TextField variant="h4" source="nombre" label="Name" />
        <TextField variant="h5" source="province.nombre" label="Province" />
        <TextField variant="h6" source="slug" label="Slug" />

        <Box m={5}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Typography color="textSecondary" style={{ paddingBottom: 10 }}>ID</Typography>
              <TextField source="id" />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Typography color="textSecondary" style={{ paddingBottom: 10 }}>Province Id</Typography>
              <TextField source="province.id" />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Typography color="textSecondary" style={{ paddingBottom: 10 }}>Destination type</Typography>
              <BooleanNumField source="tipo_destino" />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Typography color="textSecondary" style={{ paddingBottom: 10 }}>Popular</Typography>
              <BooleanNumField source="popular" />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Typography color="textSecondary" style={{ paddingBottom: 10 }}>Timezone</Typography>
              <TextField source="timezone" />
            </Grid>
          </Grid>
        </Box>

        <TabbedShowLayout>
          <Tab label="English">
            <TextField source="seo_titulo_en" label="SEO Title" />
            <TextField source="seo_descripcion_en" label="SEO Description" />
            <TextField source="seo_tags_es" label="SEO keywords" />
          </Tab>
          <Tab label="Deutsch">
            <TextField source="seo_titulo_de" label="SEO Title" />
            <TextField source="seo_descripcion_de" label="SEO Description" />
            <TextField source="seo_tags_de" label="SEO keywords" />
          </Tab>
          <Tab label="Español">
            <TextField source="seo_titulo_es" label="SEO Title" />
            <TextField source="seo_descripcion_es" label="SEO Description" />
            <TextField source="seo_tags_es" label="SEO keywords" />
          </Tab>
        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};
// ES: ["seo_descripcion_es", "seo_tags_es", "seo_titulo_es"],