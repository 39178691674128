import axios from 'axios';
import Config from './config';
import { configFroala } from '../src/components/froala/config'

axios.interceptors.response.eject(0);
axios.interceptors.response.use(
    response => response,
    error => {
        
        const {config, response: {status, data}} = error;
        console.log(data);
        const originalRequest = config;
        const errorMsg = typeof data.error === 'undefined' ? data.message : data.error;
        
        const httpError = {response: {status: status, data: errorMsg}};
        console.log(httpError);
        if (status === 401) {
            return authProvider.updateToken()
                .then(() => axios(originalRequest))
                .catch(() => {
                    authProvider.logout();
                    return Promise.reject(httpError);
                });
        }
        return Promise.reject(httpError);
    },
);
const getCurrent = (Promise) => {
    try {
        const token = localStorage.getItem('token');
        const request = new Request(Config.api_url + '/user/current', {
            headers: new Headers(
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            )
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    console.log(response);
                }
                return response.json();
            })
            .then( response => {
                if (typeof response.data === 'undefined' ||
                    typeof response.data.attributes === 'undefined' ||
                    response.data.attributes.role === 'undefined' ||
                    response.data.attributes.role.length < 1) {
                    return Promise.resolve({role: ['guest']});
                }
                const permissions = JSON.stringify(response.data.attributes);
                localStorage.setItem('permissions', permissions);

                localStorage.setItem('current', response.data.attributes.id);

                return Promise.resolve(response.data.attributes);
            });
        } catch (e) {
            return Promise.resolve({role: ['guest']});
        }
}
const authProvider = {
    login: ({ username, password }) =>  {
        const data = {
            grant_type: 'password',
            client_id: Config.client_id,
            client_secret: Config.client_secret,
            username: username,
            password: password
        };

        const request = new Request(Config.api_url_token, {
            method: 'POST',
            body: new URLSearchParams(data),
            headers: new Headers(
                { 'Content-Type': 'application/x-www-form-urlencoded' }
            )
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then( response => {
                localStorage.setItem('token', response.access_token);
                configFroala.requestHeaders.Authorization= "Bearer " + response.access_token
                localStorage.setItem('refresh_token', response.refresh_token);
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('current');
        return Promise.resolve();
    },
    checkError: (error) => {
        return Promise.resolve();
    },
    checkAuth: (params) => {
        if (params.role === 'guest') {
            const permissions = {role: ['guest']};
            return Promise.resolve(permissions);
        }
        return localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject()
    },
    getPermissions: () => {
        let permissions = localStorage.getItem('permissions');

        try {
            permissions = JSON.parse(permissions);
        } catch (e) {
            return getCurrent(Promise);
        }
        if (!permissions) {
            return getCurrent(Promise);
        } else {
            return Promise.resolve(permissions);
        }
    },
    updateToken: () => {
        const refresh_token = localStorage.getItem('refresh_token');
        const data = {
            grant_type: 'refresh_token',
            client_id: Config.client_id,
            client_secret: Config.client_secret,
            refresh_token: refresh_token,
        };

        const request = new Request(Config.api_url_token, {
            method: 'POST',
            body: new URLSearchParams(data),
            headers: new Headers(
                { 'Content-Type': 'application/x-www-form-urlencoded' }
            )
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then( response => {
                localStorage.setItem('token', response.access_token);
                configFroala.requestHeaders.Authorization= "Bearer " + response.access_token
                localStorage.setItem('refresh_token', response.refresh_token);
            });
    }
};

export default authProvider;
