import * as React from "react";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { CssBaseline, FormControl, Input, InputLabel, Toolbar, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    Title,
    useAuthenticated,
    useNotify,
    useRedirect,
    fetchStart,
    fetchEnd
} from 'react-admin';

import Container from '@material-ui/core/Container';
import Config from './config';

const useStyles = makeStyles(theme => ({
    card: {
        marginTop: theme.spacing(8)
    }
}));

const ForgotPass = ({ staticContext, ...props }) => {
    
        const classes = useStyles();
        useAuthenticated({ role: 'guest' });
        
        const [email, setEmail] = useState('');

        const dispatch = useDispatch();
        const redirect = useRedirect();
        const notify = useNotify();
        const [loading, setLoading] = useState(false);
        const [isError, setError] = useState(false);

        const handleClick = () => {
            if (email.length < 3) {
                setError(true);
            } else {
                setLoading(true);
                dispatch(fetchStart()); // start the global loading indicator 
                const url = Config.web_url + "/reset-password?email=" + email;
                fetch(url, { method: 'GET' })
                    .then(() => {
                        notify('You have been sent an email to reset your password!');
                        redirect('/login');
                    })
                    .catch((e) => {
                        notify('Error: Error!', 'warning')
                    })
                    .finally(() => {
                        setLoading(false);
                        dispatch(fetchEnd()); // stop the global loading indicator
                    });
            }
        };
        return (

                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Card className={classes.card}>
                        <Title title="My Page" />
                        <CardContent>
                            <Toolbar>
                                <FormControl fullWidth error={isError}>
                                    <InputLabel htmlFor="email">Email address</InputLabel>
                                    <Input id="email" name="email"  type="email" value={email} onChange={e => setEmail(e.target.value)} />
                                    {isError ? <FormHelperText id="component-error-text">Please, provide your email.</FormHelperText>  : ' '}
                                </FormControl>
                            </Toolbar>
                            <Toolbar>
                                <Button fullWidth variant="contained" color="secondary" onClick={handleClick} disabled={loading}>
                                    Reset Password
                                </Button>
                            </Toolbar>

                        </CardContent>
                    </Card>
        </Container>

        );
};

export default ForgotPass;