import React, { useState } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  TopToolbar,
  ListButton,
  EditButton
} from "react-admin";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { Typography, Link } from '@material-ui/core/';

import ChevronLeft from "@material-ui/icons/ChevronLeft";

const EditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const useStyles = makeStyles({
  imageField: {
    '& img': {
      objectFit: 'contain',
      minWidth: 'initial',
      maxWidth: '42em',
      maxHeight: '15em',
    }
  },
  textField: {
    fontSize: '20px'
  },
  typography: {
    marginTop: '10px'
  },
});


export const GalleryShow = (props) => {
  const [url, setUrl] = useState(null);
  const classes = useStyles();

  const CustomImageField = (props) => {
    const currentUrl = props.record.url.replace("//", 'https://')
    setUrl(currentUrl)
    return <ImageField {...props} />
  }
  return (
    <Show  {...props}
      actions={<EditActions />}
    >
      <SimpleShowLayout>
        <Button
          onClick={() => { navigator.clipboard.writeText(url) }}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SaveIcon />}
        >
          Copy URL
      </Button>
        <Typography variant="body2" gutterBottom className={classes.typography} >
          <Link href={url} target="_blank">
            {url}
          </Link>
        </Typography>
        <CustomImageField source="url" title="title" className={classes.imageField} />
        <TextField source="key" className={classes.textField} />
      </SimpleShowLayout>
    </Show>
  );
};
