import React from 'react';
import {
    Filter,
    Show,
    Create,
    Edit,
    DeleteButton,
    SimpleShowLayout,
    ShowButton,
    List,
    EditButton,
    Datagrid,
    TextField,
    TextInput,
    PasswordInput,
    DateField,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceInput,
    SelectInput,
    ChipField,
    EmailField,
    ReferenceField,
    required,
    ArrayField,
    SingleFieldList,
    AutocompleteInput,
    minLength,
    maxLength,
    email,
    SimpleForm
} from 'react-admin';
import {isSuper, isAdmin} from '../../helper';
import SimpleChipField from '../../components/simple-chip-field';

const validateName = [required(), minLength(2), maxLength(36)];
const validateEmail = [required(), email()];
const validateRole = [required()];
const validatePass = [minLength(6), maxLength(36)];

const UserFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
        <TextInput source="email" alwaysOn />
        { (isSuper(permissions) || isAdmin(permissions)) &&
        <ReferenceInput label="Partner" emptyText="All partners" source="partner_id" reference="partner">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>}
    </Filter>
);

export const UserList = ({ permissions, ...props }) => (
    <List {...props} filters={<UserFilter permissions={permissions} />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ArrayField source="role" sortable={false}>
                <SingleFieldList linkType={false}>
                    <SimpleChipField clickable={false}/>
                </SingleFieldList>
            </ArrayField>}
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ReferenceField label="Partner" source="partner_id" reference="partner">
                <TextField source="name" />
            </ReferenceField>}
            <ShowButton />
            <EditButton />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

export const UserShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ArrayField source="role">
                <SingleFieldList linkType={false}>
                    <SimpleChipField clickable={false} source="name"/>
                </SingleFieldList>
            </ArrayField>}
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ReferenceField label="Partner" source="partner_id" reference="partner">
                <TextField source="name" />
            </ReferenceField>}
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </SimpleShowLayout>
    </Show>
);
export const UserEdit = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" validate={validateName} />
            <TextInput source="email" validate={validateEmail} />
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ReferenceArrayInput validate={validateRole} label="Role" source="role" reference="role">
                <SelectArrayInput optionValue="name">
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>}
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ReferenceInput source="partner_id" reference="partner">
                <SelectInput optionText="name" />
            </ReferenceInput>}
            { (isSuper(permissions)) &&
            <PasswordInput validate={validatePass} source="new_password" />}
        </SimpleForm>
    </Edit>
);

export const UserCreate = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={validateName} />
            <TextInput source="email" validate={validateEmail} />
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ReferenceArrayInput validate={validateRole} label="Role" source="role" reference="role">
                <SelectArrayInput optionText="name" optionValue="name">
                    <SimpleChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>}
            { (isSuper(permissions) || isAdmin(permissions)) &&
            <ReferenceInput source="partner_id" reference="partner">
                <SelectInput optionText="name" />
            </ReferenceInput>}
            <PasswordInput source="password" validate={validatePass} />
        </SimpleForm>
    </Create>
);
