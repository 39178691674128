import React, { Component } from 'react';
import { connect } from 'react-redux';
import { crudGetOne, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

class SolhopUserMenuView extends Component {

    render() {
        const { profile, ...props } = this.props;
        return (
            <UserMenu label={profile ? profile.name : 'Profile'} {...props}>
                <MenuItemLink
                    to="/profile"
                    primaryText="Profile"
                    leftIcon={<SettingsIcon />}
                />
            </UserMenu>
        );
    }
}

const mapStateToProps = state => {
    const storedProfile = localStorage.getItem("permissions");
    const profile = JSON.parse(storedProfile);
    return {
        profile: profile
    };
};

const SolhopUserMenu = connect(
    mapStateToProps,
    { crudGetOne }
)(SolhopUserMenuView);
export default SolhopUserMenu;
