import React from "react";
import { Show, SimpleShowLayout, TabbedShowLayout, Tab, ReferenceField, TextField } from "react-admin";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

const FroalaEditorViewWrapper = ({ record, source }) => {
const froalaAdvertisingText = '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>'
const newRecord = record[source].replace(froalaAdvertisingText, '')
  return <FroalaEditorView model={newRecord} />;
};

const PostTitle = ({ record }) => {
  return <span>{record.location_id}</span>;
};

export const PageContentShow = (props) => {
  return (
    <Show title={<PostTitle />} {...props}>
      <SimpleShowLayout>
        <TabbedShowLayout>

          <Tab label="Location_ID">
            <ReferenceField label="Location_ID" source="location_id" reference="location" >
              <TextField source="nombre" />
            </ReferenceField>
          </Tab>

          <Tab label="English">
            <FroalaEditorViewWrapper source="en" />
          </Tab>

          <Tab label="Deutsch">
            <FroalaEditorViewWrapper source="de" />
          </Tab>

          <Tab label="Español">
            <FroalaEditorViewWrapper source="es" />
          </Tab>

        </TabbedShowLayout>
      </SimpleShowLayout>
    </Show>
  );
};
